import styled from 'styled-components';

export const Container = styled.header`
  color: ${props => props.theme.colors.white};
  background: transparent;

  padding: 14px 60px;
  margin-top: auto;

  position: fixed;
  left: 0;
  right: 0;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 18px;
    font-weight: bold;
  }

  svg {
    display: none;
  }

  @media (max-width: 520px) {
    display: none;
  }
`;

export const RightContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 520px) {
    align-items: center;
  }

  button {
    cursor: pointer;
    color: ${props => props.theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    svg {
      color: ${props => props.theme.colors.white};
      margin-left: 8px;
    }

    &:hover,
    &:hover svg {
      color: ${props => props.theme.colors.gray};
    }
  }
`;
