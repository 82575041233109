import styled from 'styled-components';

interface ContainerProps {
  fullWidth?: boolean;
  marginBottom?: number;
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.white};
  border-radius: 16px;

  opacity: 0.95;

  padding: 32px;

  display: flex;
  flex-direction: column;

  width: ${props => (props.fullWidth ? '100%' : '40%')};

  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : '0'};

  box-shadow: inset 0 0 1em transparent,
    0 0 0.4em ${props => props.theme.colors.black};

  position: relative;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;
