import styled, { css, keyframes } from 'styled-components';

import background from '../../assets/background.jpeg';

interface ContainerProps {
  quantity: number;
  researched: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  /* ${props =>
    props.researched &&
    css`
      ${props.quantity === 0
        ? css`
            margin-bottom: 0;
          `
        : props.quantity > 4
        ? css`
            margin-bottom: 230px;
          `
        : css`
            margin-bottom: 200px;
          `}
    `} */

  padding: 60px;

  z-index: 10000;

  position: relative;

  @media (max-width: 560px) {
    padding: 20px;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Animation = styled.div`
  animation: ${appearFromLeft} 1s;

  @media (max-width: 560px) {
    margin-top: 44px;
  }
`;

export const LoginArea = styled.div`
  position: absolute;
  top: 16px;
  right: 60px;

  border-radius: 16px;
  padding: 8px 16px;

  @media (max-width: 560px) {
    width: 100%;

    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardContent = styled.div`
  img {
    height: 100px;

    margin-bottom: 20px;
  }

  p {
    color: #565656;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
  }

  @media (max-width: 360px) {
    img {
      width: 100%;
    }
  }
`;

export const FormContent = styled.div`
  width: 70%;

  @media (max-width: 862px) {
    width: 100%;
  }
`;

export const Actions = styled.div`
  margin-top: 32px;

  width: 30%;

  display: flex;
  align-items: center;

  @media (max-width: 560px) {
    width: 100%;
  }
`;
