import { createGlobalStyle } from 'styled-components';
import { darken, lighten, shade } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    background: ${props => props.theme.colors.background};

    ::-webkit-scrollbar {
      width: 10px;

      position: absolute;

      background: transparent;
      border-radius: inherit;
    }

    ::-webkit-scrollbar-track {
      background: ${props => lighten(0.1, props.theme.colors.primary)};
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => darken(0.1, props.theme.colors.primary)};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${props => shade(0.2, props.theme.colors.primary)};
    }
  }

  body, input, textarea, button {
    font: 14px Poppins, Archivo, sans-serif;
  }

  a {
    text-decoration: none !important;
  }

  ul {
    list-style: none;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
