import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.button<ContainerProps>`
  width: 100%;

  background: ${props => props.theme.colors.primary};
  box-shadow: inset 0 0 1em transparent,
    0 0 0.4em ${props => props.theme.colors.primary};
  border-radius: 12px;

  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: 14px;

  padding: 8px 16px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: no-drop;
    `}

  &:hover {
    background: ${props => shade(0.2, props.theme.colors.primary)};
  }

  &:disabled {
    opacity: 0.8;
  }
`;
