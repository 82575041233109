import styled from 'styled-components';
import { shade } from 'polished';

export const PharmaciesContent = styled.div`
  overflow-y: scroll;

  padding-right: 16px;

  max-height: 268px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px ${props => props.theme.colors.primary};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => shade(0.2, props.theme.colors.primary)};
  }

  span {
    display: block;
    margin-bottom: 8px;
  }
`;

export const Pharmacy = styled.div`
  border-top: 1px solid #232323;

  padding: 12px 0;

  strong {
    font-size: 16px;
  }

  p {
    font-size: 14px;

    margin-top: 2px;
  }

  @media (max-width: 560px) {
    padding: 20px;
  }
`;

export const EmptyContainer = styled.div``;
