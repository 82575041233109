import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Link from '../../components/Link';
import Select from '../../components/Select';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

import PharmaciesList, { IPhamacy } from './PharmaciesList';

import { Result } from '../../@types/apiResult';
import { superaApi, chainApi } from '../../services';
import { getConfiguration, getValidationErrors } from '../../utils';

import supera from '../../assets/supera_v2.png';

import {
  Container,
  Animation,
  LoginArea,
  CardContent,
  FormContent,
  Actions,
} from './styles';

interface IOption {
  value: number | string;
  label: string;
}

interface IState {
  id: number;
  uf: string;
  name: string;
}

interface ICounty {
  id: number;
  name: string;
}

interface INeighborhood {
  name: string;
  countyId: number;
}

const Pharmacies: React.FC = () => {
  const { token, appToken } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const countyRef = useRef<any>(null);
  const neighborhoodRef = useRef<any>(null);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [countyDisabled, setCountyDisabled] = useState<boolean>(true);
  const [neighborhoodDisabled, setNeighborhoodDisabled] =
    useState<boolean>(true);

  const [states, setStates] = useState<IOption[]>([]);
  const [counties, setCounties] = useState<IOption[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<IOption[]>([]);
  const [pharmacies, setPharmacies] = useState<IPhamacy[]>([]);

  const [selectedState, setSelectedState] = useState<number | string>(0);
  const [selectedCounty, setSelectedCounty] = useState<number | string>(0);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState<
    number | string
  >(0);

  const [researched, setResearched] = useState<boolean>(false);

  const handleStateChange = useCallback((e: any) => {
    const { value } = e as IOption;

    setSelectedState(value);
    setCountyDisabled(false);

    countyRef.current?.clear();
    neighborhoodRef.current?.clear();
  }, []);

  const handleCountyChange = useCallback((e: any) => {
    const { value } = e as IOption;

    setSelectedCounty(value);
    setNeighborhoodDisabled(false);
    setButtonDisabled(false);

    neighborhoodRef.current?.clear();
  }, []);

  const handleNeighborhoodChange = useCallback((e: any) => {
    const { value } = e as IOption;

    setSelectedNeighborhood(value);
  }, []);

  const handleSubmit = useCallback(
    async (formData: object) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          uf: Yup.string().required('Selecione um estado!'),
          counties: Yup.string().required('Selecione um município!'),
          neighborhoods: Yup.string().optional(),
        });

        await schema.validate(formData, { abortEarly: false });

        const baseUrl = await getConfiguration('Supera.Stores');

        const url = selectedNeighborhood
          ? `${baseUrl}/${selectedCounty}/${selectedNeighborhood}`
          : `${baseUrl}/${selectedCounty}`;

        const response = await superaApi.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log(response);

        const { data } = response.data;

        setPharmacies(data);
        setResearched(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao localizar as farmácias!',
          description: 'Ocorreu um erro na listagem de farmácias.',
        });
      }
    },
    [token, addToast, selectedCounty, selectedNeighborhood],
  );

  useEffect(() => {
    async function loadStates() {
      const url = await getConfiguration('Supera.States');

      const response = await superaApi.get<{ data: IState[] }>(url);

      const { data } = response.data;

      if (!data) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao buscar os estados',
          description:
            'Mas já fomos notificados e estamos trabalhando para corrigir esse problema!',
        });

        return;
      }

      const options: IOption[] = data.map(option => ({
        value: option.id,
        label: `${option.name} [${option.uf}]`,
      }));

      setStates(options);
    }

    loadStates();
  }, [addToast]);

  useEffect(() => {
    async function loadCounties() {
      if (selectedState > 0) {
        const url = await getConfiguration('Supera.Counties');

        const response = await superaApi.get<{ data: ICounty[] }>(
          `${url}/${selectedState}`,
        );

        const { data } = response.data;

        if (!data) {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao buscar os municípios',
            description:
              'Mas já fomos notificados e estamos trabalhando para corrigir esse problema!',
          });

          return;
        }

        const options: IOption[] = data.map(option => ({
          value: option.id,
          label: option.name,
        }));

        setCounties(options);
      }
    }

    loadCounties();
  }, [selectedState, addToast]);

  useEffect(() => {
    async function loadNeighborhoods() {
      if (selectedCounty > 0) {
        const benefitId = await getConfiguration('plan_id');
        const url = await getConfiguration('Supera.Neighborhoods');

        const response = await chainApi.get<Result<INeighborhood[]>>(
          `${url}/${benefitId}/${selectedCounty}?Page=1&ItemsPerPage=1000`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        const { data } = response.data;

        if (!data) {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao buscar os bairros',
            description:
              'Mas já fomos notificados e estamos trabalhando para corrigir esse problema!',
          });

          return;
        }

        const options: IOption[] = data.map(option => ({
          value: option.name,
          label: option.name,
        }));

        setNeighborhoods(options);
      }
    }

    loadNeighborhoods();
  }, [appToken, selectedCounty, addToast]);

  return (
    <Container researched={researched} quantity={pharmacies.length}>
      <Animation>
        <Card marginBottom={60} style={{ zIndex: 1 }}>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CardContent>
              <ReactLink to="/">
                <img src={supera} alt="Supera" />
              </ReactLink>

              <p>
                Olá seja muito bem-vindo ao serviço de busca de farmácias.
                Selecione abaixo os dados de sua localização para encontrarmos a
                farmácia mais próxima a você.
              </p>

              <FormContent>
                <Select
                  name="uf"
                  options={states}
                  placeholder="Estados"
                  onChange={handleStateChange}
                />

                <Select
                  ref={countyRef}
                  name="counties"
                  options={counties}
                  placeholder="Município"
                  onChange={handleCountyChange}
                  disabled={countyDisabled}
                />

                <Select
                  ref={neighborhoodRef}
                  name="neighborhoods"
                  options={neighborhoods}
                  placeholder="Bairro"
                  onChange={handleNeighborhoodChange}
                  disabled={neighborhoodDisabled}
                />
              </FormContent>

              <Actions>
                <Button type="submit" disabled={buttonDisabled}>
                  Buscar
                </Button>
              </Actions>
            </CardContent>
          </Form>
        </Card>
      </Animation>

      {researched && (
        <Animation>
          <PharmaciesList pharmacies={pharmacies} />
        </Animation>
      )}

      <LoginArea>
        <Link to="/login">Acessar área restrita</Link>
      </LoginArea>
    </Container>
  );
};

export default Pharmacies;
