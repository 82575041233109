import { AxiosResponse } from 'axios';
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from 'react';

import { Result } from '../@types/apiResult';

import { authApi } from '../services';
import { getConfiguration } from '../utils';

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  token: string | undefined;
  appToken: string | undefined;
  signIn(
    signInCredentials: SignInCredentials,
  ): Promise<AxiosResponse<Result<any>>>;
  signOut(): void;
  refreshToken: () => Promise<string>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(() => {
    const storageValue = localStorage.getItem('@supera:token');

    if (storageValue) {
      return storageValue;
    }

    return undefined;
  });

  const [appToken, setAppToken] = useState<string | undefined>(() => {
    const storageValue = localStorage.getItem('@supera:appToken');

    if (storageValue) {
      return storageValue;
    }

    return undefined;
  });

  const authenticate = useCallback(async () => {
    const username = await getConfiguration('username');
    const password = await getConfiguration('password');
    const client_id = await getConfiguration('client_id');

    const url = await getConfiguration('EndPoint.Authentication.Authenticate');

    const response = await authApi.get(url, {
      headers: { username, password, client_id },
    });

    const { data } = response.data;

    localStorage.setItem('@supera:token', data?.token.accessToken);

    setToken(data?.token.accessToken);

    return data?.token.accessToken;
  }, []);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  const signIn = useCallback(
    async ({
      username,
      password,
    }: SignInCredentials): Promise<AxiosResponse<Result<any>>> => {
      const client_id = await getConfiguration('secret_client_id');

      const url = await getConfiguration(
        'EndPoint.Authentication.Authenticate',
      );

      const response = await authApi.get<Result<any>>(url, {
        headers: { username, password, client_id },
      });

      const { data } = response.data;

      if (data) {
        setAppToken(data.token.accessToken);
        localStorage.setItem('@supera:appToken', data.token.accessToken);
      }

      return response;
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@supera:appToken');

    setAppToken(undefined);
  }, []);

  const refreshToken = useCallback(async () => {
    const updatedToken = await authenticate();

    return updatedToken;
  }, [authenticate]);

  const value = useMemo(
    () => ({ token, appToken, signIn, signOut, refreshToken }),
    [token, appToken, signIn, signOut, refreshToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
