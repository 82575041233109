import React from 'react';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './useAuth';
import { BenefitProvider } from './useRules';
import { ToastProvider } from './useToast';

import theme from '../styles/theme';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ToastProvider>
          <BenefitProvider>{children}</BenefitProvider>
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
