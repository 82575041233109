import { AppError } from '../errors/AppError';

export default function validateCPF(
  path: string,
  message: string,
  cpf: string,
): boolean {
  try {
    let Soma;
    let Resto;

    const strCPF = cpf.replace(/\D/g, '');

    Soma = 0;

    if (strCPF === '00000000000' || strCPF === '') {
      throw new AppError(path, message);
    }

    for (let i = 1; i <= 9; i++) {
      Soma += Number(strCPF.substring(i - 1, i)) * (11 - i);
    }

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;

    if (Resto !== Number(strCPF.substring(9, 10))) {
      throw new AppError(path, message);
    }

    Soma = 0;

    for (let i = 1; i <= 10; i++) {
      Soma += Number(strCPF.substring(i - 1, i)) * (12 - i);
    }

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;

    if (Resto !== Number(strCPF.substring(10, 11))) {
      throw new AppError(path, message);
    }

    return true;
  } catch (err) {
    throw new AppError(path, message);
  }
}
