import React, { ButtonHTMLAttributes, useMemo } from 'react';
import Ink from 'react-ink';

import Loading from '../Loading';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  loadingMessage?: string;
  style?: object;
}

const Button: React.FC<ButtonProps> = ({
  disabled = false,
  loading,
  loadingMessage,
  style,
  children,
  ...rest
}) => {
  const isShowLoading = useMemo(() => {
    return loading && loadingMessage;
  }, [loading, loadingMessage]);

  const isDisabled = useMemo(() => {
    return (!disabled && loading) || disabled;
  }, [disabled, loading]);

  const isShowInk = useMemo(() => {
    return !disabled && !loading;
  }, [disabled, loading]);

  return (
    <Container type="button" {...rest} disabled={isDisabled} style={style}>
      {isShowLoading ? <Loading message={loadingMessage} /> : children}

      {isShowInk && <Ink />}
    </Container>
  );
};

export default Button;
