import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select';
import { FiInfo } from 'react-icons/fi';
import { useField } from '@unform/core';
import { ThemeContext } from 'styled-components';

import { Container, Error, useStyles } from './styles';

interface SelectProps extends ReactSelectProps<OptionTypeBase> {
  name: string;
  disabled?: boolean;
  containerStyle?: object;
  loadAction: boolean;
}

interface SelectRef {
  clear(): void;
}

const Select: React.ForwardRefRenderFunction<SelectRef, SelectProps> = (
  { name, disabled, containerStyle, ...rest },
  ref,
) => {
  const { colors } = useContext(ThemeContext);

  const selectRef = useRef<any>(null);

  const [showIsErrored, setShowIsErrored] = useState<boolean>(true);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setShowIsErrored(false);
  }, []);

  const handleInputBlur = useCallback(() => {
    setShowIsErrored(true);
  }, []);

  useImperativeHandle(ref, () => ({
    clear() {
      selectRef.current?.select.commonProps.setValue([]);
    },
  }));

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: selectRef.current,
      getValue: (iRef: any) => {
        if (rest.isMulti) {
          if (!iRef.state.value) {
            return [];
          }
          return iRef.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!iRef.state.value) {
          return '';
        }
        return iRef.state.value.value;
      },
      setValue: (iRef: any, value) => {
        console.log(iRef, value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container className="container" style={containerStyle}>
      {error && showIsErrored && (
        <Error title={error}>
          <FiInfo color={colors.red} size={20} />
        </Error>
      )}

      <ReactSelect
        id={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        classNamePrefix="react-select"
        styles={useStyles(error)}
        ref={selectRef}
        isDisabled={disabled}
        {...rest}
      />
    </Container>
  );
};

export default forwardRef(Select);
