import styled, { keyframes } from 'styled-components';

import background from '../../assets/background.jpeg';

export const Container = styled.div`
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  padding: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 560px) {
    padding: 20px;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Animation = styled.div`
  animation: ${appearFromRight} 1s;

  width: 530px;

  > div {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 50px;

    object-fit: contain;

    margin-bottom: 20px;
  }

  p {
    color: #565656;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
  }
`;

export const FormContent = styled.div`
  width: 70%;

  margin: 16px 0;

  @media (max-width: 862px) {
    width: 100%;
  }
`;

export const Actions = styled.div`
  width: 30%;

  display: flex;
  align-items: center;

  @media (max-width: 560px) {
    width: 100%;
  }
`;
