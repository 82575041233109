import styled, { css } from 'styled-components';
import { darken, shade } from 'polished';

const states = {
  show: css`
    opacity: 1;
    visibility: visible;
  `,
  hide: css`
    opacity: 0;
    visibility: hidden;
  `,
};

export const Container = styled.div<{ show: boolean }>`
  z-index: 9999999999999;

  ${props => (props.show ? states.show : states.hide)};

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  padding: 80px;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const backgroundStates = {
  show: css`
    opacity: 0.5;
    visibility: visible;
  `,
  hide: css`
    opacity: 0;
    visibility: hidden;
  `,
};

export const Background = styled.div<{ show: boolean }>`
  background: ${props => darken(0.6, props.theme.colors.black)};

  width: 100vw;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 7777777;

  ${props => (props.show ? backgroundStates.show : backgroundStates.hide)};

  transition: opacity 200ms;
`;

const mode = {
  light: css`
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.white};
  `,
};

const size = {
  sm: css`
    width: 30vw;
  `,
  md: css`
    width: 50vw;
  `,
  lg: css`
    width: 70vw;
  `,
  xl: css`
    width: 90vw;
  `,
};

interface ContentProps {
  mode: 'light';
  size: 'sm' | 'md' | 'lg' | 'xl';
}

export const Content = styled.div<ContentProps>`
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: 8px;
  overflow-y: auto;
  position: absolute;

  max-height: 80%;

  z-index: 7777777;

  ::-webkit-scrollbar {
    width: 10px;

    position: absolute;

    background: ${props => props.theme.colors.white};
    border-radius: inherit;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${props => props.theme.colors.white};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => darken(0.1, props.theme.colors.primary)};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => shade(0.2, props.theme.colors.primary)};
  }

  ${props => size[props.size]};

  header,
  main,
  footer {
    ${props => mode[props.mode]}
  }

  @media (max-width: 1300px) {
    width: 60%;
  }

  @media (max-width: 760px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const Header = styled.header`
  color: inherit;

  border-top-left-radius: 8px;
  /* border-top-right-radius: 8px; */

  width: 100%;

  display: flex;
  justify-content: flex-end;

  h1 {
    color: inherit;
  }

  button {
    color: inherit;
    margin-top: 8px;
    margin-right: 8px;
  }
`;

export const Body = styled.main`
  width: 100%;

  padding: 32px 16px;

  display: flex;

  border-bottom-left-radius: 8px;
  /* border-bottom-right-radius: 8px; */
`;
