import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { Container, Label, Error } from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

const Checkbox: React.FC<CheckboxProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { fieldName, defaultValue = [], error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      {options.map((option, index) => (
        <Label
          id={`${name}-${option.id}`}
          htmlFor={option.id}
          key={option.id}
          isErrored={!!error}
          isFocused={isFocused}
        >
          {option.label}

          <input
            id={option.id}
            name={name}
            type="checkbox"
            value={option.value}
            defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            {...rest}
          />

          <span>
            <div>{error && <Error title={error} />}</div>
          </span>
        </Label>
      ))}
    </Container>
  );
};

export default Checkbox;
