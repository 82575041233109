import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  width: 100%;

  margin: 8px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface LabelProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Label = styled.label<LabelProps>`
  display: block;
  position: relative;

  padding-left: 24px;
  font-size: 12px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  color: ${props => props.theme.colors.black};

  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    cursor: pointer;
  }

  > span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 18px;
    width: 18px;

    border-radius: 6px;
    background-color: #eee;
    border: 1px solid ${props => props.theme.colors.primary};

    ${props =>
      props.isErrored &&
      css`
        border-color: ${props.theme.colors.red};
      `}

    ${props =>
      props.isFocused &&
      css`
        border-color: ${lighten(0.2, props.theme.colors.primary)};
        box-shadow: inset 0 0 1em transparent,
          0 0 0.4em ${props.theme.colors.primary};
      `}

    div {
      position: relative;
    }
  }

  &:hover input ~ span {
    background-color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
  }

  & input:checked ~ span {
    background-color: ${props => props.theme.colors.primary};
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    top: 2px;
    left: 5px;

    width: 3px;
    height: 7px;

    border: solid white;
    border-width: 0 3px 3px 0;

    transform: rotate(45deg);

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;

  span {
    color: ${props => props.theme.colors.white} !important;
    background: ${props => props.theme.colors.red};

    &::before {
      top: -6px;
      left: 10px;
      bottom: 100% !important;
      border-color: ${props => props.theme.colors.red} transparent;
      border-width: 0px 6px 6px 6px;
    }

    height: 32px;
    bottom: 0;
    top: calc(100% + 12px);
    left: -2px;
    transform: translateX(0%);
  }
`;
