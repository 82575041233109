interface IOption {
  label: string;
  value: string;
}

export default function getYesOrNo(): IOption[] {
  const yesOrNo = [
    { label: 'Sim', value: '1' },
    { label: 'Não', value: '0' },
  ];

  return yesOrNo;
}
