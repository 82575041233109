import React from 'react';

import Card from '../../../components/Card';

import { PharmaciesContent, Pharmacy, EmptyContainer } from './styles';

export interface IPhamacy {
  id: number;
  tradeName: string;
  companyName: string;
  addressLine: string;
  stateId: number;
  state: IState;
  countyId: number;
  county: ICounty;
  neighborhood: string;
}

export interface IState {
  id: number;
  uf: string;
}

export interface ICounty {
  id: number;
  name: string;
}

interface PharmaciesListProps {
  pharmacies: IPhamacy[];
}

const PharmaciesList: React.FC<PharmaciesListProps> = ({ pharmacies }) => {
  return pharmacies.length === 0 ? (
    <Card fullWidth style={{ zIndex: 0 }}>
      <EmptyContainer>
        <p>Nenhuma farmácia foi encontrada!</p>
      </EmptyContainer>
    </Card>
  ) : (
    <Card fullWidth style={{ zIndex: 0 }}>
      <PharmaciesContent>
        <span>Veja as farmácias próximas a você:</span>
        {pharmacies?.map(pharmacy => (
          <Pharmacy key={pharmacy.id}>
            <strong>{pharmacy.tradeName}</strong>
            <p>{pharmacy.companyName}</p>
            <p>
              {pharmacy.addressLine} - {pharmacy.neighborhood} -{' '}
              {pharmacy.county.name} - {pharmacy.state.uf}
            </p>
          </Pharmacy>
        ))}
      </PharmaciesContent>
    </Card>
  );
};

export default PharmaciesList;
