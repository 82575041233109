interface IOption {
  label: string;
  value: string;
}

export default function getBrasilUFs(): IOption[] {
  const ufs = [
    { cod: 12, name: 'Acre', label: 'AC', value: 'AC' },
    { cod: 27, name: 'Alagoas', label: 'AL', value: 'AL' },
    { cod: 16, name: 'Amapá', label: 'AP', value: 'AP' },
    { cod: 13, name: 'Amazonas', label: 'AM', value: 'AM' },
    { cod: 29, name: 'Bahia', label: 'BA', value: 'BA' },
    { cod: 23, name: 'Ceará', label: 'CE', value: 'CE' },
    { cod: 53, name: 'Distrito Federal', label: 'DF', value: 'DF' },
    { cod: 32, name: 'Espírito Santo', label: 'ES', value: 'ES' },
    { cod: 52, name: 'Goiás', label: 'GO', value: 'GO' },
    { cod: 21, name: 'Maranhão', label: 'MA', value: 'MA' },
    { cod: 51, name: 'Mato Grosso', label: 'MT', value: 'MT' },
    { cod: 50, name: 'Mato Grosso do Sul', label: 'MS', value: 'MS' },
    { cod: 31, name: 'Minas Gerais', label: 'MG', value: 'MG' },
    { cod: 15, name: 'Pará', label: 'PA', value: 'PA' },
    { cod: 41, name: 'Paraná', label: 'PR', value: 'PR' },
    { cod: 25, name: 'Paraíba', label: 'PB', value: 'PB' },
    { cod: 26, name: 'Pernambuco', label: 'PE', value: 'PE' },
    { cod: 22, name: 'Piauí', label: 'PI', value: 'PI' },
    { cod: 33, name: 'Rio de Janeiro', label: 'RJ', value: 'RJ' },
    { cod: 24, name: 'Rio Grande do Norte', label: 'RN', value: 'RN' },
    { cod: 43, name: 'Rio Grande do Sul (*)', label: 'RS', value: 'RS' },
    { cod: 11, name: 'Rondônia', label: 'RO', value: 'RO' },
    { cod: 14, name: 'Roraima', label: 'RR', value: 'RR' },
    { cod: 42, name: 'Santa Catarina', label: 'SC', value: 'SC' },
    { cod: 35, name: 'São Paulo', label: 'SP', value: 'SP' },
    { cod: 28, name: 'Sergipe', label: 'SE', value: 'SE' },
    { cod: 17, name: 'Tocantins', label: 'TO', value: 'TO' },
  ];

  return ufs;
}
