import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Fallback } from '../../components/Fallback';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import Link from '../../components/Link';
import ModalValidatedCoupon from '../../components/ModalValidatedCoupon';
import ModalAcceptTerm from '../../components/ModalAcceptTerm';
import Select from '../../components/Select';

import { superaApi, beneficiaryApi } from '../../services';

import {
  cpfCnpj,
  delay,
  getBrasilUFs,
  getConfiguration,
  getValidationErrors,
  getYesOrNo,
  throwException,
  validateCPF,
} from '../../utils';

import { Result } from '../../@types/apiResult';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useRules } from '../../hooks/useRules';
import { ValidationError } from '../../errors/ValidationError';

import supera from '../../assets/supera_v2.png';

import {
  Container,
  Animation,
  LoginArea,
  CardContent,
  FormContent,
  Row,
  AcceptTerms,
  Actions,
} from './styles';

interface ICupom {
  id: number;
  nrCoupon: string;
  cpf: string;
  crm: string;
  acceptanceTerm: boolean;
  createdAt: Date;
  excel: string;
  presentation: number;
}

interface RegisterFormData {
  hasCoupon: boolean;
  nrCoupon: number;
  cpf: string;
  crm: string;
  acceptanceTerm: [string];
  uf: string;
  presentation: number;
}

const Register: React.FC = () => {
  const { refreshToken } = useAuth();
  const { addToast } = useToast();
  const { hasCouponEnabled } = useRules();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [block, setblock] = useState<boolean>(true);
  const [firtTime, setFirstTime] = useState<boolean>(true);
  const [fallback, setFallback] = useState<boolean>(false);
  const [hasCoupon, setHasCoupon] = useState<boolean>(true);

  const [presentations, setPresentation] = useState([]);
  const [cupom, setCupom] = useState('');

  const [isSuccessShow, setIsSuccessShow] = useState<boolean>(false);
  const [isAcceptTermsShow, setIsAcceptTermsShow] = useState<boolean>(false);

  const toggleShowSuccess = useCallback(() => {
    setIsSuccessShow(!isSuccessShow);
  }, [isSuccessShow]);

  const toggleShowAcceptTerm = useCallback(() => {
    setIsAcceptTermsShow(!isAcceptTermsShow);
  }, [isAcceptTermsShow]);

  const handleChangeHasCoupon = useCallback((e: any) => {
    setHasCoupon(e.value === '1');
  }, []);

  async function getPresentation() {
    if (cupom !== '') {
      try {
        setblock(true);
        setFallback(true);
        const token = await refreshToken();
        const response = await superaApi.get<Result<any>>(
          `api/v1/Beneficiary/GetAppresentation/${cupom}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        const { data } = response.data;
        setPresentation(data);
        setblock(false);
        setFallback(false);
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Produto não encontrado!',
          description: 'Não foi localizado produto correspondente',
        });
        setFallback(false);
      }
    }
  }

  useEffect(() => {
    if (!firtTime) {
      setPresentation([]);
      getPresentation();
    } else {
      setFirstTime(false);
    }
  }, [cupom]);

  const handleSubmit = useCallback(
    async (formData: RegisterFormData) => {
      try {
        const updatedToken = await refreshToken();

        setLoading(true);

        formRef.current?.setErrors({});

        let hasCouponObjectValidation = {};

        if (hasCouponEnabled) {
          hasCouponObjectValidation = {
            hasCoupon: Yup.string().required(
              'Por favor selecione a opção se possui um cupom!',
            ),
            nrCoupon: Yup.string().when('hasCoupon', {
              is: '1',
              then: Yup.string().required('O campo cupom é obrigatório!'),
            }),
          };
        } else {
          hasCouponObjectValidation = {
            nrCoupon: Yup.string().required('O campo cupom é obrigatório!'),
            presentation: Yup.string().required(
              'O campo produto é obrigatório!',
            ),
          };
        }

        const schema = Yup.object().shape({
          ...hasCouponObjectValidation,
          cpf: Yup.string().required('O campo CPF é obrigatório!'),
          crm: Yup.string().required('O campo CRM é obrigatório!'),
          uf: Yup.string().required('O campo UF é obrigatório!'),
          acceptanceTerm: Yup.boolean().oneOf(
            [true],
            'Por favor, leia e aceite os termos!',
          ),
        });

        await schema.validate(formData, { abortEarly: false });

        validateCPF('cpf', 'Digite um CPF válido!', formData.cpf);

        const headers = { Authorization: `Bearer ${updatedToken}` };

        const {
          nrCoupon,
          cpf: formCpf,
          crm,
          acceptanceTerm: acceptanceTermForm,
          uf,
          presentation,
        } = formData;

        await delay(3000);

        const cpf = formCpf.replace(/\D/g, '');

        const url = await getConfiguration('Supera.Coupons');
        const acceptanceTerm = acceptanceTermForm[0] === '1';

        if (hasCoupon) {
          const response = await superaApi.put<Result<ICupom>>(
            url,
            { nrCoupon, cpf, crm, acceptanceTerm, uf, presentation },
            { headers },
          );

          throwException(response.data);
        }

        addToast({
          type: 'success',
          title: 'Cupom validado com sucesso!',
          description:
            'Perfeito, seu cupom já foi validado e já pode ser usado.',
        });

        setLoading(false);
        setIsSuccessShow(true);
      } catch (err: any) {
        setLoading(false);
        setblock(false);
        const isValidationError =
          err instanceof Yup.ValidationError || err instanceof ValidationError;

        if (isValidationError) {
          const errors = getValidationErrors(err as Yup.ValidationError);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Falha na validação de cupom!',
          description: err.message,
        });
      }
    },
    [addToast, hasCouponEnabled, refreshToken, hasCoupon],
  );

  return (
    <Container>
      <ModalValidatedCoupon show={isSuccessShow} toggle={toggleShowSuccess} />
      <ModalAcceptTerm show={isAcceptTermsShow} toggle={toggleShowAcceptTerm} />

      <Animation>
        <Card>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CardContent>
              <ReactLink to="/">
                <img src={supera} alt="Supera" />
              </ReactLink>

              <p>Preencha os dados abaixo para validar o seu cupom.</p>

              <FormContent>
                {hasCouponEnabled && (
                  <Select
                    name="hasCoupon"
                    options={getYesOrNo()}
                    onChange={handleChangeHasCoupon}
                    placeholder="Possui cupom?"
                  />
                )}

                <Input
                  name="cpf"
                  placeholder="CPF"
                  maxLength={14}
                  maskPattern={cpfCnpj}
                />

                {hasCoupon && (
                  <>
                    <Input
                      name="nrCoupon"
                      placeholder="Número do cupom"
                      maxLength={30}
                      onChange={e => setCupom(e.target.value)}
                      value={cupom}
                    />
                    <Fallback isActive={fallback} />
                    <Select
                      name="presentation"
                      options={presentations}
                      placeholder="Produtos"
                      loadingMessage="Pesquisando Produtos"
                      isDisabled={block}
                    />
                  </>
                )}

                <Row>
                  <Input
                    name="crm"
                    placeholder="CRM"
                    maxLength={9}
                    containerStyle={{ margin: 0 }}
                  />

                  <Select
                    name="uf"
                    options={getBrasilUFs()}
                    placeholder="UF"
                    containerStyle={{ margin: 0 }}
                  />
                </Row>

                <AcceptTerms>
                  <Checkbox
                    name="acceptanceTerm"
                    options={[
                      {
                        id: '1',
                        value: '1',
                        label: 'Declaro que li e aceito os ',
                      },
                    ]}
                  />

                  <button type="button" onClick={toggleShowAcceptTerm}>
                    termos de consentimento
                  </button>
                </AcceptTerms>
              </FormContent>

              <Actions loading={loading ? 1 : 0}>
                <Button
                  type="submit"
                  loading={loading}
                  loadingMessage={
                    hasCoupon ? 'Validando seu cupom' : 'Realizando cadastro'
                  }
                >
                  OK
                </Button>
              </Actions>
            </CardContent>
          </Form>
        </Card>
      </Animation>

      <LoginArea>
        <Link to="/login">Acessar área restrita</Link>
      </LoginArea>
    </Container>
  );
};

export default Register;
