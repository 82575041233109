import React from 'react';

import { Container } from './styles';

interface CardProps {
  fullWidth?: boolean;
  marginBottom?: number;
  style?: object;
}

const Card: React.FC<CardProps> = ({
  fullWidth,
  marginBottom,
  style,
  children,
}) => (
  <Container fullWidth={fullWidth} marginBottom={marginBottom} style={style}>
    {children}
  </Container>
);

export default Card;
