import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import * as Yup from 'yup';

import Header from '../../components/Header';
import Card from '../../components/Card';
import Input from '../../components/Input';
import Button from '../../components/Button';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

import supera from '../../assets/supera_v2.png';

import delay from '../../utils/delay';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Animation,
  CardContent,
  FormContent,
  Actions,
} from './styles';

interface LoginFormData {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [isShow, setIsShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleShowPassword = useCallback(() => setIsShow(!isShow), [isShow]);

  const handleSubmit = useCallback(
    async (formData: LoginFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required('Informe seu nome de usuário!'),
          password: Yup.string().required('Digite sua senha secreta!'),
        });

        await schema.validate(formData, { abortEarly: false });

        const { username, password } = formData;

        await delay(3000);

        const response = await signIn({ username, password });

        const { data } = response;

        if (!data.success) {
          if (data.messages) {
            data.messages.map((item: any) => {
              throw new Error(item.message);
            });
          } else {
            throw new Error(data.error.message);
          }
        }

        addToast({
          type: 'success',
          title: 'Login efetuado com sucesso!',
          description: 'Bem-vindo, você já pode começar a gerar seus cupons.',
        });

        history.push('/coupons');
      } catch (err: any) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Falha na autenticação!',
          description: err.message,
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <>
      <Header />
      <Container>
        <Animation>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <CardContent>
                <Link to="/">
                  <img src={supera} alt="Supera" />
                </Link>

                <p>Digite suas credenciais para realizar o login.</p>

                <FormContent>
                  <Input name="username" placeholder="Nome de usuário" />

                  <Input
                    name="password"
                    type={isShow ? 'text' : 'password'}
                    icon={isShow ? FiEye : FiEyeOff}
                    iconClick={toggleShowPassword}
                    placeholder="Sua senha secreta"
                  />
                </FormContent>

                <Actions>
                  <Button
                    type="submit"
                    loading={loading}
                    loadingMessage="Entrando"
                  >
                    Entrar
                  </Button>
                </Actions>
              </CardContent>
            </Form>
          </Card>
        </Animation>
      </Container>
    </>
  );
};

export default Login;
