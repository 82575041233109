const theme = {
  colors: {
    background: '#fbfbfb',

    primary: '#005f9d',

    white: '#fbfbfb',

    black: '#212322',

    red: '#E0514F',

    gray: '#acacac',
  },
};

export default theme;
