import styled, { keyframes } from 'styled-components';

import background from '../../assets/background.jpeg';

export const Container = styled.div`
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  padding: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 560px) {
    padding: 20px;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Animation = styled.div`
  animation: ${appearFromRight} 1s;

  width: 500px;

  > div {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 100px;

    object-fit: contain;

    margin-bottom: 20px;
  }

  p {
    color: #565656;
    font-size: 14px;
    text-align: left;
    line-height: 22px;

    &:nth-of-type(2) {
      margin-bottom: 32px;
    }
  }
`;

export const FormContent = styled.div`
  width: 100%;

  display: block;

  padding: 0 64px;
  margin: 16px 0;

  @media (max-width: 862px) {
    width: 100%;
  }
`;

export const Actions = styled.div`
  width: auto;

  display: flex;
  align-items: center;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const OptionsContainer = styled.div`
  margin-top: 24px;

  width: 100%;

  border: 1px dashed ${props => props.theme.colors.gray};

  padding: 16px 0;

  display: flex;
  flex-direction: column;

  span {
    text-align: center;

    margin-bottom: 16px;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;

    div + div {
      margin-top: 16px;
    }
  }

  padding: 0 64px;

  div {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 560px) {
    > div {
      width: 100%;

      display: grid;

      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const BackLinksContainer = styled.div`
  margin-top: 24px;
  padding: 0 64px;

  a {
    color: ${props => props.theme.colors.black};
    font-weight: bold;
    text-decoration: none;

    opacity: 0.6;

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: all 0.2s;

    &:hover {
      opacity: 1;
      color: ${props => props.theme.colors.primary};
    }

    svg {
      margin-right: 8px;
    }
  }
`;
