import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  color: ${props => props.theme.colors.black};
  background: ${props => props.theme.colors.white};
  border-radius: 22px;
  border: 1px solid ${props => props.theme.colors.gray};

  padding: 0 16px;
  margin: 8px 0;

  width: 100%;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.red};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${lighten(0.2, props.theme.colors.primary)};
      box-shadow: inset 0 0 1em transparent,
        0 0 0.4em ${props.theme.colors.primary};
    `}

  &:hover {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: inset 0 0 1em transparent,
      0 0 0.4em ${props => props.theme.colors.primary};
  }

  input {
    background: transparent;
    flex: 1;
    width: 100%;
    border: 0;
    color: ${props => props.theme.colors.black};

    &::placeholder {
      color: ${props => props.theme.colors.gray};
    }
  }
`;

export const IconContainer = styled.div<Omit<ContainerProps, 'isErrored'>>`
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 8px;

  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 8px;

  &:hover {
    background: #eee;

    svg {
      color: ${props => props.theme.colors.primary};
    }
  }

  svg {
    color: ${props => props.theme.colors.gray};
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;

  span {
    color: ${props => props.theme.colors.white} !important;
    background: ${props => props.theme.colors.red};

    &::before {
      border-color: ${props => props.theme.colors.red} transparent;
    }
  }
`;
