import React from 'react';

import logo from '../../assets/epharma.png';

import { Container, LeftContent, RightContent, EpharmaLogo } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <LeftContent>
        <span>+55 11 4689-8686</span>

        <p>Al. Mamoré, 989 - 9° andar - Alphaville - Barueri - SP</p>
      </LeftContent>

      <RightContent>
        <EpharmaLogo>
          <span>Autorizador</span>

          <img src={logo} alt="ePharma" />
        </EpharmaLogo>
      </RightContent>
    </Container>
  );
};

export default Footer;
