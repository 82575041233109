import styled, { keyframes } from 'styled-components';

import background from '../../assets/background.jpeg';

export const Container = styled.div`
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  padding: 60px;

  position: relative;

  @media (max-width: 560px) {
    padding: 50px;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Animation = styled.div`
  animation: ${appearFromLeft} 1s;

  @media (max-width: 560px) {
    margin-top: 44px;
  }
`;

export const LoginArea = styled.div`
  position: absolute;
  top: 16px;
  right: 60px;

  border-radius: 16px;
  padding: 8px 16px;

  @media (max-width: 560px) {
    width: 100%;

    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardContent = styled.div`
  img {
    height: 80px;

    object-fit: contain;

    margin-bottom: 20px;
  }

  p {
    color: #565656;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
  }

  @media (max-width: 360px) {
    img {
      width: 100%;
    }
  }
`;

export const FormContent = styled.div`
  width: 70%;

  @media (max-width: 862px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AcceptTerms = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 54px;

  position: relative;

  button {
    position: absolute;
    left: 164px;

    border-bottom: 1px dashed #454545;

    font-size: 12px;

    @media (max-width: 480px) {
      left: 25px;
      top: 24px;
    }
  }
`;

interface ActionsProps {
  loading?: 0 | 1;
}

export const Actions = styled.div<ActionsProps>`
  width: ${props => (props.loading === 1 ? '44%' : '30%')};

  display: flex;
  align-items: center;

  transition: width 400ms;

  @media (max-width: 560px) {
    width: 100%;
  }
`;
