import styled from 'styled-components';
import { useContext } from 'react';
import { OptionTypeBase, Styles } from 'react-select';
import { ThemeContext } from 'styled-components';
import { shade } from 'polished';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  position: relative;

  margin: 8px 0;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 4px;

  position: absolute;
  top: 10px;
  right: 54px;

  z-index: 2;

  span {
    color: ${props => props.theme.colors.white} !important;
    background: ${props => props.theme.colors.red};

    &::before {
      border-color: ${props => props.theme.colors.red} transparent;
    }
  }
`;

export function useStyles(error: string | undefined) {
  const { colors } = useContext(ThemeContext);

  const customStyles = {
    menu: (styles: any) => ({
      ...styles,
      zIndex: 4,
    }),
    menuList: (styles: any) => ({
      ...styles,
      zIndex: 4,
    }),
    control: (styles: any, { isDisabled }: any) => ({
      ...styles,
      borderRadius: 22,
      padding: '0 5px',
      border: `1px solid ${colors.gray} !important`,
      '&:hover': {
        border: `1px solid ${colors.primary} !important`,
        boxShadow: `inset 0 0 1em transparent, 0 0 0.4em ${colors.primary}`,
      },
      '&:focus': {
        color: colors.primary,
        borderColor: colors.primary,
      },
      color: colors.black,
      borderColor: error !== undefined ? colors.red : colors.primary,
      boxShadow: 'none',
      background: isDisabled ? shade(0.2, colors.white) : colors.white,
      opacity: isDisabled ? 0.4 : 1,
      minWidth: '120px',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: colors.black,
    }),
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected && colors.primary,
      color: isSelected ? colors.white : colors.black,
    }),
  } as Partial<Styles<OptionTypeBase, false>> | undefined;

  return customStyles;
}
