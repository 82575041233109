import { Result } from '../@types/apiResult';

function throwException(data: Result<any>) {
  if (!data.success) {
    data.messages.map(item => {
      if (item.message.length > 80) throw new Error('Ocorreu um erro interno!');

      throw new Error(item.message);
    });
  }
}

export default throwException;
