import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiArrowLeft } from 'react-icons/fi';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Switch from '../../components/Switch';

import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { useRules } from '../../hooks/useRules';

import { Result } from '../../@types/apiResult';
import { superaApi } from '../../services';
import { ValidationError } from '../../errors/ValidationError';
import { getConfiguration, getValidationErrors } from '../../utils';

import supera from '../../assets/supera_v2.png';

import {
  Container,
  Animation,
  CardContent,
  FormContent,
  Actions,
  OptionsContainer,
  Options,
  BackLinksContainer,
} from './styles';

interface CuponsFormData {
  quantity: number;
  distritalNumber: number;
  product: number;
}

const Cupons: React.FC = () => {
  const { token } = useAuth();
  const { addToast } = useToast();
  const {
    benefitEnabled,
    setBenefitEnabled,
    hasCouponEnabled,
    setHasCouponEnabled,
  } = useRules();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [distritalNumbers, setDistritalsNumbers] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getDistritalsNumbers() {
      const response = await superaApi.get<Result<any>>(
        'api/v1/DistritalNumber',
        { headers: { Authorization: `Bearer ${token}` } },
      );

      const { data } = response.data;

      setDistritalsNumbers(data);
    }

    async function getProducts() {
      const config = await getConfiguration('products');
      setProducts(JSON.parse(config));
    }

    getProducts();
    getDistritalsNumbers();
  }, [token]);

  const handleBenefitChecked = useCallback(async () => {
    const url = await getConfiguration('Supera.RulesConfigurations');

    await superaApi.put<Result<any>>(
      url,
      { benefitEnabled: !benefitEnabled, hasCouponEnabled },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    setBenefitEnabled(!benefitEnabled);
  }, [token, benefitEnabled, hasCouponEnabled, setBenefitEnabled]);

  const handleHasCouponChecked = useCallback(async () => {
    const url = await getConfiguration('Supera.RulesConfigurations');

    await superaApi.put<Result<any>>(
      url,
      { benefitEnabled, hasCouponEnabled: !hasCouponEnabled },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    setHasCouponEnabled(!hasCouponEnabled);
  }, [token, benefitEnabled, hasCouponEnabled, setHasCouponEnabled]);

  const handleSubmit = useCallback(
    async (formData: CuponsFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const msg =
          'O valor informado não é um valor válido, insira um valor numérico e entre 1 e 100.000';

        const schema = Yup.object().shape({
          quantity: Yup.number().min(1, msg).max(100000, msg),
        });

        if (String(formData.distritalNumber) === '') {
          throw new ValidationError(
            'distritalNumber',
            'É obrigatório informar um distrital para os cupons',
          );
        }

        if (String(formData.quantity) === '') {
          throw new ValidationError(
            'quantity',
            'É obrigatório informar a quantidade de cupons',
          );
        }

        if (String(formData.product) === '') {
          throw new ValidationError(
            'quantity',
            'É obrigatório informar a quantidade de cupons',
          );
        }

        await schema.validate(formData, { abortEarly: false });

        const { quantity, distritalNumber, product } = formData;

        const url = await getConfiguration('Supera.Coupons');

        const response = await superaApi.post<Result<any>>(
          url,
          { quantity, distritalNumber, product },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        const { data } = response;

        if (!data.success) {
          data.messages.map(item => {
            throw new Error(item.message);
          });
        }

        const pattern =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

        const a = document.createElement('a');

        document.body.appendChild(a);

        a.style.display = 'none';
        a.href = `${pattern}${encodeURI(data.data.excel)}`;

        const now = new Date();
        const date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
        const hours = `${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        const formattedDate = `${date}_${hours}`;

        a.download = `Cupons_${formattedDate}.xlsx`;
        a.click();

        addToast({
          type: 'success',
          title: 'Cupons gerados com sucesso!',
          description:
            'Excelente, seus cupons já foram gerados e exportados via excel para seu gerenciamento.',
        });

        setLoading(false);
      } catch (err: any) {
        setLoading(false);

        if (
          err instanceof Yup.ValidationError ||
          err instanceof ValidationError
        ) {
          const errors = getValidationErrors(err as Yup.ValidationError);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Falha na validação de cupom!',
          description: err.message,
        });
      }
    },
    [token, addToast],
  );

  return (
    <>
      <Header />
      <Container>
        <Animation>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <CardContent>
                <Link to="/">
                  <img src={supera} alt="Supera" />
                </Link>

                <p>Informe a quantidade de cupons para serem gerados.</p>

                <FormContent>
                  <Select
                    name="distritalNumber"
                    options={distritalNumbers}
                    placeholder="Número Distrital"
                  />

                  <Select
                    name="product"
                    options={products}
                    placeholder="Produtos"
                  />

                  <Input
                    name="quantity"
                    type="number"
                    placeholder="Quantidade de cupons"
                    min="1"
                    max="100000"
                  />
                </FormContent>

                <p>(Digitar valor entre 1 e 100.000)</p>

                <Actions>
                  <Button
                    type="submit"
                    loading={loading}
                    loadingMessage="Gerando cupons"
                  >
                    Gerar Cupons
                  </Button>
                </Actions>

                <OptionsContainer>
                  <span>Configurações de Regras:</span>

                  <Options>
                    <div>
                      <p>Ativar benefício</p>
                      <Actions>
                        <Switch
                          toggleCheck={handleBenefitChecked}
                          checked={benefitEnabled}
                        />
                      </Actions>
                    </div>

                    <div>
                      <p>Ativar posse de cupom</p>
                      <Actions>
                        <Switch
                          toggleCheck={handleHasCouponChecked}
                          checked={hasCouponEnabled}
                        />
                      </Actions>
                    </div>
                  </Options>
                </OptionsContainer>

                <BackLinksContainer>
                  <Link to="/">
                    <FiArrowLeft size={16} />
                    Voltar para home
                  </Link>
                </BackLinksContainer>
              </CardContent>
            </Form>
          </Card>
        </Animation>
      </Container>
    </>
  );
};

export default Cupons;
