import React, { InputHTMLAttributes } from 'react';
import { CheckBoxWrapper, CheckBox, CheckBoxLabel } from './styles';

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  toggleCheck: () => void;
}

const Switch: React.FC<SwitchProps> = ({ checked, toggleCheck }) => {
  return (
    <div>
      <CheckBoxWrapper>
        <CheckBox
          id="checkbox"
          type="checkbox"
          onChange={toggleCheck}
          checked={checked}
        />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
    </div>
  );
};

export default Switch;
