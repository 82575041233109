import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  a {
    white-space: nowrap;
    width: 100%;

    background: ${props => props.theme.colors.primary};
    box-shadow: inset 0 0 1em transparent,
      0 0 0.4em ${props => props.theme.colors.primary};
    border-radius: 12px;

    color: ${props => props.theme.colors.white};
    font-weight: bold;
    font-size: 14px;

    padding: 8px 16px;

    position: relative;

    &:hover {
      background: ${props => shade(0.2, props.theme.colors.primary)};
    }
  }
`;
