import React from 'react';

import {
  Container,
  Message,
  Animation,
  Loader,
  One,
  Two,
  Three,
} from './styles';

interface LoadingProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <Container>
      {message && <Message>{message}</Message>}

      <Animation>
        <Loader>
          <One />

          <Two />

          <Three />
        </Loader>
      </Animation>
    </Container>
  );
};

export default Loading;
