import React from 'react';

import Button from '../Button';
import Modal from '../Modal';

import supera from '../../assets/supera_v2.png';

import { ModalContent, ModalMessages } from './styles';

interface ModalValidatedCouponProps {
  show: boolean;
  toggle: () => void;
}

const ModalValidatedCoupon: React.FC<ModalValidatedCouponProps> = ({
  show,
  toggle,
}) => {
  return (
    <Modal show={show} size="sm" toggle={toggle}>
      <ModalContent>
        <img src={supera} alt="Supera" />

        <ModalMessages>
          <p>Cupom validado com sucesso!</p>
          <p>busque a farmácia participante</p>
          <p>mais próxima a você e boas compras :)</p>
        </ModalMessages>

        <Button style={{ width: '40%' }} onClick={toggle}>
          OK
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default ModalValidatedCoupon;
