import React from 'react';

import Button from '../Button';
import Modal from '../Modal';

import supera from '../../assets/supera_v2.png';

import { ModalContent, ModalMessages } from './styles';

interface ModalAcceptTermProps {
  show: boolean;
  toggle: () => void;
}

const ModalAcceptTerm: React.FC<ModalAcceptTermProps> = ({ show, toggle }) => {
  return (
    <Modal show={show} size="lg" toggle={toggle}>
      <ModalContent>
        <img src={supera} alt="Supera" />

        <ModalMessages>
          <p>
            <strong>TERMO DE CONSENTIMENTO - COLETA DE DADOS PESSOAIS</strong>
          </p>
          <p>
            Por meio do presente TERMO DE CONSENTIMENTO, declaro que fui
            devidamente orientado (a) pela Supera RX Medicamentos Ltda., pessoa
            jurídica com sede na Capital do Estado de São Paulo, à Av. Das
            Nações Unidas, 22.532, Bl 04, Vila Almeida, São Paulo, SP, CEP
            04795-00, inscrita no CNPJ sob no. 15.759.157/0001-72 (“Supera”), e
            Inscrição Estadual no. 145.393.234.119, por meio do presente TERMO
            DE CONSENTIMENTO, e, assim, concordo com as condições estipuladas a
            seguir:
          </p>
          <br />
          <p>
            1 - Autorização. Em total observância à Lei Geral de Proteção de
            Dados Pessoais (Lei nº 13.709/2018) e demais normativas sobre
            proteção de dados pessoais, manifesto-me de forma livre, expressa e
            inequívoca em autorizar a Supera a realizar o tratamento de meus
            dados pessoais para as seguintes finalidades: permitir o acesso ao
            medicamento prescrito pelo médico, com condição especial de compra
            em rede de farmácias credenciada ao Programa de Benefícios Supera,
            para início do tratamento.
          </p>
          <br />
          <p>
            2 - Finalidade da coleta. Concordo que os meus dados pessoais serão
            utilizados pela Supera para, além das finalidades descritas no item
            anterior, armazenamento em uma base de dados de responsabilidade da
            Supera e utilização posterior para comunicação e aprimoramento dos
            serviços prestados pela Supera.
          </p>
          <br />
          <p>
            3 - Compartilhamento. Fica a Supera autorizada a compartilhar os
            meus dados pessoais com outros Agentes de Tratamento, caso seja
            necessário para as finalidades descritas neste Termo, incluindo o
            armazenamento em cloud computing instalada no exterior, observados
            os princípios e garantias estabelecidos pela Lei Geral de Proteção
            de Dados Pessoais e outras legislações aplicáveis ao caso.
          </p>
          <br />
          <p>
            4 - Confidencialidade. Atesto que me foi cientificado o compromisso
            assumido pela Supera de tratar meus dados pessoais, inclusive os
            dados sensíveis, de forma sigilosa e confidencial, mantendo-os em
            ambiente seguro e não sendo utilizados para qualquer fim que não os
            descritos nos itens acima.
          </p>
          <br />
          <p>
            5 - Conforme item 2 acima, a autorização ora concedida se estenderá
            a eventuais parceiras, sociedades de propósitos específicos, outras
            associações e empresas do mesmo grupo, mesmo que futuras ou
            provenientes de fusões, aquisições, incorporações, cisões ou
            qualquer outra operação societária que possa alterar a estrutura
            atual da Supera, em razão de suas atividades.
          </p>
          <br />
          <p>
            6 - Revogação. Tenho conhecimento de que, a qualquer tempo, posso
            revogar o consentimento ora fornecido relacionado ao tratamento dos
            meus dados pessoais, hipótese em que deixarei de fazer parte do
            banco de dados da Supera.
          </p>
          <br />
          <p>
            6.1 Declaro e concordo que os dados pessoais por mim fornecidos
            poderão ser armazenados, mesmo após o término do tratamento,
            inclusive após a revogação do consentimento, para (i) cumprimento de
            obrigação legal ou regulatória ou (ii) desde que anonimizados.
          </p>
          <br />
          <p>
            7 - Canais de atendimento. Estou ciente que posso receber
            informações, tirar dúvidas ou realizar solicitações relacionadas ao
            presente Termo pelo endereço de e-mail:
            [privacidadededados@superarx.com.br].
          </p>
          <br />
          <p>
            Desse modo, ao clicar no espaço reservado para a manifestação de meu
            consentimento, ratifico a minha concordância com os termos aqui
            estabelecidos, firmando o presente Termo como se assinado
            eletronicamente fosse, que concordo e reconheço ser válido em sua
            integralidade.
          </p>
        </ModalMessages>

        <Button style={{ width: '40%' }} onClick={toggle}>
          OK
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default ModalAcceptTerm;
