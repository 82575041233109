import axios from 'axios';

const baseURL = window.supera.base;
// process.env.NODE_ENV === 'development'
//   ? 'http://localhost:57598'
//   : window.supera.base;

const api = axios.create({ baseURL });

export default api;
