import React from 'react';

import AppProvider from './hooks';
import Footer from './components/Footer';
import Routes from './routes';

import GlobalStyle from './styles/global';

const App: React.FC = () => {
  return (
    <AppProvider>
      <Routes />
      <Footer />
      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
