import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 160px;
  }
`;

export const ModalMessages = styled.div`
  margin: 64px 0;

  @media (max-width: 360px) {
    margin: 22px 0;
  }

  p {
    text-align: center;
    line-height: 20px;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      font-size: 12px;
    }
  }
`;
