import md5 from 'md5';
import api from '../services/api';

const getConfiguration = async (key: string) => {
  const md5Key = md5(key);

  const response = await api.get(`/api/v1/Configurations/${md5Key}`);

  return response.data.data;
};

export default getConfiguration;
