import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  InputHTMLAttributes,
} from 'react';
import InputMask from 'react-input-mask';
import Ink from 'react-ink';
import { useField } from '@unform/core';
import { FiInfo } from 'react-icons/fi';
import { ThemeContext } from 'styled-components';
import { IconBaseProps } from 'react-icons/lib';

import { Container, IconContainer, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconClick?: () => void;
  mask?: string;
  maskPattern?: (value: string, cb: (t: any) => void) => void;
  containerStyle?: object;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  iconClick,
  mask,
  maskPattern,
  containerStyle,
  ...rest
}) => {
  const { colors } = useContext(ThemeContext);

  const [value, setValue] = useState<string>('');

  const maskedInputRef = useRef<InputMask>(null);
  const commonInputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showIsErrored, setShowIsErrored] = useState<boolean>(true);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);

    setShowIsErrored(false);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setShowIsErrored(true);
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      if (maskPattern) maskPattern(e.target.value, setValue);
    },
    [maskPattern],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: mask ? maskedInputRef.current : commonInputRef.current,
      path: 'value',
    });
  }, [registerField, fieldName, mask]);

  return (
    <Container
      isErrored={!!error && showIsErrored}
      isFocused={isFocused}
      style={containerStyle}
    >
      {mask ? (
        <InputMask
          id={name}
          mask={mask}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={maskedInputRef}
          {...rest}
        />
      ) : (
        <input
          id={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={commonInputRef}
          value={maskPattern && value}
          onChange={maskPattern && handleChange}
          {...rest}
        />
      )}

      {error && showIsErrored && (
        <Error title={error}>
          <FiInfo color={colors.red} size={20} />
        </Error>
      )}

      {Icon && (
        <IconContainer onClick={iconClick} isFocused={isFocused}>
          <Icon size={20} />
          <Ink />
        </IconContainer>
      )}
    </Container>
  );
};

export default Input;
