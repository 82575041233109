import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Result } from '../@types/apiResult';

import { superaApi } from '../services';
import { getConfiguration } from '../utils';
import { useAuth } from './useAuth';

interface BenefitContextData {
  benefitEnabled: boolean;
  setBenefitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  hasCouponEnabled: boolean;
  setHasCouponEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const BenefitContext = createContext<BenefitContextData>(
  {} as BenefitContextData,
);

const BenefitProvider: React.FC = ({ children }) => {
  const { token } = useAuth();

  const [benefitEnabled, setBenefitEnabled] = useState<boolean>(true);
  const [hasCouponEnabled, setHasCouponEnabled] = useState<boolean>(false);

  useEffect(() => {
    async function getSuperaConfigurations() {
      const url = await getConfiguration('Supera.RulesConfigurations');

      const response = await superaApi.get<Result<any>>(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { data } = response.data;

      setBenefitEnabled(data?.benefitEnabled);
      setHasCouponEnabled(data?.hasCouponEnabled);
    }

    getSuperaConfigurations();
  }, [token]);

  const value = useMemo(
    () => ({
      benefitEnabled,
      setBenefitEnabled,
      hasCouponEnabled,
      setHasCouponEnabled,
    }),
    [benefitEnabled, setBenefitEnabled, hasCouponEnabled, setHasCouponEnabled],
  );

  return (
    <BenefitContext.Provider value={value}>{children}</BenefitContext.Provider>
  );
};

function useRules(): BenefitContextData {
  const context = useContext(BenefitContext);

  return context;
}

export { BenefitProvider, useRules };
