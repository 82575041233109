import React from 'react';
import Ink from 'react-ink';
import { Link as ReactLink } from 'react-router-dom';

import { Container } from './styles';

interface LinkProps {
  to?: any;
  onclick?: any;
}

const Link: React.FC<LinkProps> = ({ to, children, onclick }) => {
  return (
    <Container>
      <ReactLink to={to} onClick={onclick}>
        {children}
        <Ink />
      </ReactLink>
    </Container>
  );
};

export default Link;
