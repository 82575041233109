import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { useRules } from '../hooks/useRules';

import Route from './Route';

import Landing from '../pages/Landing';
import Pharmacies from '../pages/Pharmacies';
import Register from '../pages/Register';

import Login from '../pages/Login';
import Coupons from '../pages/Coupons';

const Routes: React.FC = () => {
  const { benefitEnabled } = useRules();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/pharmacies" component={Pharmacies} />
        <Route path="/login" component={Login} />
        <Route path="/coupons" component={Coupons} isPrivate />
        {benefitEnabled && <Route path="/register" component={Register} />}
        <Route path="" component={Landing} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
