import React from 'react';
import { FiMenu, FiLogOut } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { useAuth } from '../../hooks/useAuth';

import { Container, LeftContent, RightContent } from './styles';

const Header: React.FC = () => {
  const { appToken, signOut } = useAuth();

  const { colors } = useTheme();

  return (
    <Container>
      <LeftContent>
        <FiMenu size={20} color={colors.white} />

        <span>Área administrativa</span>

        <p>Apenas para acessos restritos</p>
      </LeftContent>

      {appToken && (
        <RightContent>
          <button type="button" onClick={signOut}>
            Sair
            <FiLogOut size={16} />
          </button>
        </RightContent>
      )}
    </Container>
  );
};

export default Header;
