import styled from 'styled-components';

export const Container = styled.footer`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};

  padding: 14px 60px;
  margin-top: auto;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 520px) {
    align-items: center;
  }
`;

export const RightContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 520px) {
    align-items: center;
  }
`;

export const EpharmaLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 18px;
  }

  img {
    width: 160px;
  }
`;
