import React from 'react';
import { FiX } from 'react-icons/fi';

import { Container, Background, Content, Header, Body } from './styles';

interface ModalProps {
  show: boolean;
  mode?: 'light';
  size: 'sm' | 'md' | 'lg' | 'xl';
  toggle(): void;
}

const Modal: React.FC<ModalProps> = ({
  show,
  mode,
  size,
  toggle,
  children,
}) => {
  return (
    <Container show={show} className="modal">
      <Background show={show} onClick={toggle} />

      <Content mode={mode || 'light'} size={size} className="modalContent">
        <Header>
          <button type="button" onClick={toggle}>
            <FiX size={26} color="#aaa" />
          </button>
        </Header>

        <Body>{children}</Body>
      </Content>
    </Container>
  );
};

export default Modal;
