import React, { useCallback, useState } from 'react';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Link from '../../components/Link';

import { useRules } from '../../hooks/useRules';

import supera from '../../assets/supera_v2.png';

import {
  Container,
  Animation,
  LoginArea,
  CardContent,
  Actions,
  InactiveMessage,
} from './styles';

const Landing: React.FC = () => {
  const { benefitEnabled } = useRules();

  const [messageEnable, setMessageVisible] = useState<boolean>(false);

  const buttonHandler = useCallback(
    async function ToggleMessage() {
      if (benefitEnabled) {
        window.location.href = '/register';
      } else if (!messageEnable) {
        setMessageVisible(true);
      }
    },
    [benefitEnabled, messageEnable],
  );

  return (
    <Container>
      <Animation>
        <Card>
          {messageEnable ? (
            <InactiveMessage>
              O programa está suspenso temporariamente e será reativado em
              breve.
            </InactiveMessage>
          ) : (
            <div />
          )}
          <CardContent>
            <img src={supera} alt="Supera" />

            <p>Olá, seja muito bem-vindo ao programa de benefícios Supera.</p>
            <p>Selecione abaixo a ação que deseja.</p>

            <Actions>
              <Link to="/pharmacies">Farmácias participantes</Link>
              {benefitEnabled ? (
                <Link to="/register">Valide aqui o seu cupom</Link>
              ) : (
                <Button onClick={buttonHandler}>Valide aqui o seu cupom</Button>
              )}
            </Actions>
          </CardContent>
        </Card>
      </Animation>

      <LoginArea>
        <Link to="/login">Acessar área restrita</Link>
      </LoginArea>
    </Container>
  );
};

export default Landing;
