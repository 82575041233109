import React from 'react';

import { Bars } from 'react-loader-spinner';

interface FallbackProps {
  isActive: boolean;
}

export function Fallback({ isActive }: FallbackProps) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    >
      <Bars
        height="80"
        width="80"
        color="#005f9d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={isActive}
      />
    </div>
  );
}
